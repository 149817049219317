.wrap {
    margin: 0 auto;
    min-width: 460px;
    height: 100vh;
    background-color: #FBF7FC;
    overflow: auto;
}

* {
    margin: 0;
    box-sizing: border-box;
    outline: none;
    border: none;
    font-family: 'Pretendard';
}

.msger-header{
    display: flex;
    justify-content: space-between;
    font-size: large;
    font-weight: bold;
    padding: 0 14px;
    background: rgba(255, 255, 255, 0.60);
    /* background: linear-gradient(90deg, #212D35 0%, #212D35 100%); */
    color: var(--sky-header-font);
    height: 50px;
    /* border-bottom: 3px solid #212D35; */
    /* position: relative; */
    align-items: center;
}

.msger-header-title{
    width: 100%;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 6px;
    flex-grow: 1;
    justify-content: center;
}
  
.header-logo-frame{
    float: left;
    margin-left: 15px;
    margin-top: 5px;
}

.home-container {
    margin: 20px 20px 40px 20px;
}

.section-title {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 10px;
    color: rgb(138 31 136);
}

.userId-input {
    width: 70%;
    height: 40px;
    border-radius: 10px;
    margin-right: 10px;
    padding-left: 15px;
}

.upload-area {
    display: flex;
    justify-content: center;
}

.link-btn {
    width: 80%;
    height: 5vh;
    cursor: pointer;
    border-radius: 10px;
    margin-right: 10px;
    background-color: #ffb4da82;
}

.copy-btn {
    cursor: pointer;
    border-radius: 10px;
    height: 5vh;
}

.data-already-uploaded {
    margin-bottom: 20px;
}

.data-already-uploaded p {
    margin-bottom: 5px;
}

.suggest-section {
    display: flex;
    flex-direction: column;
}

.suggest-section input {
    width: 75%;
    height: 40px;
    margin-bottom: 10px;
    border-radius: 10px;
    padding-left: 15px;
    /* flex: 0.7 1 auto; */
}

.suggest-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.suggest-btn button {
    margin-bottom: 10px;
    height: 40px;
    border-radius: 10px;
    width: 30%;
}

@keyframes wave {
    0%, 100% {
    transform: translateY(0);
    }
    50% {
    transform: translateY(-20px);
    }
}
  
/* .wave-text {
display: inline-block;
animation: wave 2s ease-in-out infinite;
font-size: 24px;
font-weight: bold;
color: #333;
} */

.title-info-area {
    display: flex;
    justify-content: center;
}

.title-info-area img {
    width: 25px;
    height: 25px;
    margin: 4px 0 0 5px;
    cursor: pointer;
}