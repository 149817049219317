@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
:root {
    --Size25 : 600 25px/normal Pretendard;
    --Size20 : 600 20px/normal Pretendard;

    --titleStyle : 600 30px/normal Pretendard;
    --contentStyle : 600 20px/normal Pretendard;
    --inputStyle : 400 20px/normal Pretendard;

    --tableHeadStyle : 600 23px/normal Pretendard;
    --tableBodyStyle : 400 21px/normal Pretendard;
    
    --spacing1 : -0.36px;
}
.table-frame {
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0 0;
    background-color: #fff;
}

.table-th {
    font: var(--tableHeadStyle);
    letter-spacing: var(--spacing1);
    border-bottom: 1px solid var(--gray-01,#ccc7cc);
    padding: 10px;
    height: 45px;
}

.table-body-container {
    height: 45px;
}
.table-body-container.selected{
    background-color: #f0f0f0;
}
.table-td {
    font: var(--tableBodyStyle);
    letter-spacing: var(--spacing1);
    padding: 10px; 
    height: 45px;
    width: fit-content;
    white-space: normal; 
    word-break: break-word; 
}
.table-body-container:hover{
    background-color: #f0f0f0;
}

.table-td.unidentified {
    color: #dc2626; 
}

.table-td.confirm {
    color: #059669;
}

.table-td.deleted {
    color: #6b7280;
}

.table-td.edit {
    color: #2563eb;  
}

.table-td.admin_edit {
    font-weight: 600;
    color: #6d28d9;  
}