@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
:root {
    --Size25 : 600 25px/normal Pretendard;
    --Size20 : 600 20px/normal Pretendard;

    --titleStyle : 600 30px/normal Pretendard;
    --contentStyle : 600 20px/normal Pretendard;
    --inputStyle : 400 20px/normal Pretendard;

    --tableHeadStyle : 600 20px/normal Pretendard;
    --tableBodyStyle : 400 20px/normal Pretendard;
    
    --spacing1 : -0.36px;
}
.chat-management-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
/* 헤더영역 */
.chat-management-header{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 920px;
    height: 40px;
    border: 1px solid #000;
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 10px;
}
.chat-management-header-title{
    font: var(--Size25);
    letter-spacing: var(--spacing1);
}

.chat-management-content{
    background-color: #fbf7fc;
    width: 95%;
    height: 90vh;
    border-radius: 10px;
}
.chat-management-move-button{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 15px;
    gap: 15px;
}
.go-to-login{
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #63226A;
    color: white;
    border: none;
    padding: 5px;
    cursor: pointer;
    width: 150px;
    height: 40px;
    font: var(--Size20);
    letter-spacing: var(--spacing1);
    border-radius: 10px;
}
.go-to-chatbot{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #63226A;
    color: white;
    border: none;
    padding: 5px;
    cursor: pointer;
    width: 150px;
    height: 40px;
    font: var(--Size20);
    letter-spacing: var(--spacing1);
    border-radius: 10px;
}
.content-container{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 10px;
}
.pdf-container{
    width: 18%;
}
.table-container{
    width: 82%;
}
/* 메세지 데이터 영역*/
.message-data-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 800px;
}
.wrap-board{
    display: flex;
    width: 100%;
    height: 80vh;
    /* background-color: white; */
    max-height: 580px;
    min-height: 500px;
    margin: 24px auto;
    justify-content: center;
  }
  