.file-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border: 2px dashed #ffb4da82;
    border-radius: 8px;
    width: 400px;
    margin: 20px auto;
}

.dropzone {
    padding: 1rem;
    border: 2px dashed  #ffb4da82;
    background-color: #f9f9f9;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.dropzone:hover {
    background-color:  #ffb4daFF;
}

.file-preview {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-height: 200px;
    overflow-y: auto;
}

.file-item {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    background-color: #f1f1f1;
    border-radius: 4px;
}

.upload-button {
    padding: 0.5rem 1rem;
    background-color: #ffb4da82;
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.upload-button:hover {
    background-color: #ffb4daFF;
}