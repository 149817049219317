@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
:root {
    --Size25 : 600 25px/normal Pretendard;
    --Size20 : 600 20px/normal Pretendard;

    --titleStyle : 600 30px/normal Pretendard;
    --contentStyle : 600 20px/normal Pretendard;
    --inputStyle : 400 20px/normal Pretendard;

    --tableHeadStyle : 600 20px/normal Pretendard;
    --tableBodyStyle : 400 20px/normal Pretendard;
    
    --spacing1 : -0.36px;
}
.table-nav-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 60px;
    background-color: #7C2B84;
    border-radius: 10px 10px 0px 0px;
}
.table-nav-item{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 15px;
    padding: 0 15px;
}
.table-nav-item-text {
    font: var(--Size20);
    letter-spacing: var(--spacing1);
    color: white;
    width: 160px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.table-nav-item-text:hover {
    background-color: #9E49A6;
}
.active {
    background-color: #9E49A6;
}