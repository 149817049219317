@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
:root {
    --Size25 : 600 25px/normal Pretendard;
    --Size20 : 600 20px/normal Pretendard;

    --titleStyle : 600 30px/normal Pretendard;
    --contentStyle : 600 20px/normal Pretendard;
    --inputStyle : 400 20px/normal Pretendard;

    --tableHeadStyle : 600 20px/normal Pretendard;
    --tableBodyStyle : 400 20px/normal Pretendard;
    
    --spacing1 : -0.36px;
}
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.login-box-container {
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 550px;
    border: 3px solid #63226A;
    background-color: #FBF7FC;
    justify-content: center;
}

/* 헤더 영역 */
.login-header-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 130px;
    height: 130px;
}

.login-header-container h1 {
    font: var(--titleStyle);
    letter-spacing: var(--spacing1);
}

/* 로그인 폼 영역 */
.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

/* 라벨 스타일 */
.input-label {
    float: left;
    margin-left: 15px;
    font: var(--contentStyle);
    letter-spacing: var(--spacing1);
}

/* 입력 필드 공통 스타일 */
.text-input.internal-input {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 8px;
    font: var(--inputStyle);
    letter-spacing: var(--spacing1);
}

.text-input.internal-input :focus {
    border-color: #63226A;
    box-shadow: 0 0 0 2px rgba(99, 34, 106, 0.25);
}

/* 버튼 컨테이너 */
.login-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

/* 로그인 버튼 스타일 */
.login-button-container button {
    background-color: #63226A;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    font: var(--Size20);
    letter-spacing: var(--spacing1);
    border-radius: 10px;
}