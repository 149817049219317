@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
:root {
    --titleStyle : 600 25px/normal Pretendard;
    --contentStyle : 600 18px/normal Pretendard;
    --messageStyle : 400 18px/normal Pretendard;
    --inputStyle : 400 20px/normal Pretendard;
    --messageTimeStyle : 400 14px/normal Pretendard;

    --spacing1 : -0.36px;
}

.chatbot-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.chatbot-box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 520px; 
    height: 90vh;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
/* 헤더 영역 */
.chatbot-header-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    padding: 10px;
    border-bottom: 1px solid #CCC7CC;
}
.chatbot-header-logo{
    width: 130px;
    height: auto;
}
.chatbot-header-title {
    font: var(--titleStyle);
    letter-spacing: var(--spacing1);
    margin-left: auto;
}
.go-to-management-button {
    background-color: #63226A;
    color: white;
    border: none;
    padding: 5px;
    cursor: pointer;
    width: fit-content;
    font: var(--contentStyle);
    letter-spacing: var(--spacing1);
    border-radius: 10px;
    margin: 0;
    margin-left: auto;
}

/* 채팅 영역 */
.chatbot-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #FBF7FC;
    padding: 15px;
    overflow-y: auto;
}

.message {
    width: fit-content;
    max-width: 80%;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 15px;
}
.bot-message {
    align-self: flex-start;
    margin-right: auto;
    border-radius: 10px 10px 10px 0px;
    border: 1px solid var(--gray_01, #CCC7CC);
    background: var(--white, #FFF);
    box-shadow: 12px 12px 24px 0px rgba(124, 43, 132, 0.08);
    font: var(--messageStyle);
    letter-spacing: var(--spacing1);
    justify-content: center;
    text-align: left;
    word-break: keep-all;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.bot-message li {
    margin-left: 15px;
    margin-bottom: 5px;
}
.user-message {
    margin-top: 15px;
    text-align: left;
    align-self: flex-end;
    margin-left: auto;
    border-radius: 10px 10px 0px 10px;
    border: 1px solid var(--gray_01, #CCC7CC);
    background: var(--view_02, #fbffd3);
    box-shadow: 12px 12px 24px 0px rgba(124, 43, 132, 0.08);
    font: var(--messageStyle);
    letter-spacing: var(--spacing1);
    padding-right: 10px;
    word-break: keep-all;
}
.message-time{
    font: var(--messageTimeStyle);
    letter-spacing: var(--spacing1);
    text-align: right;
    margin-top: 5px;
}
/* 입력 영역 */
.chatbot-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.2);
    padding: 14px 20px;
    font-size: 14px;
    width: 100%;
    color: #FFFFFF;
    gap: 20px;
    border-radius: 0px 0px 10px 10px;
}

.chatbot-input{
    background: white;
    padding: 14px;
    width: 65%;
    height: 40px;
    border-radius: 100px;
    color: black;
    float: left;
    font: var(--inputStyle);
    letter-spacing: var(--spacing1);
    border: none;
}
.chatbot-input:focus {
    outline: none;
}

.go-to-home {
    width: 35px;
    height: 35px;
    cursor: pointer;
}
.send-button-container{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #33BBFF;
    border-radius: 999px;
    width: 46px;
    height: 38px;
    padding-right: 5px;
    cursor: pointer;
}
.send-button {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.blurred {
    filter: blur(0.5px);
}

.loading-overlay {
    position: absolute;
    width: 520px; 
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: rgba(135, 206, 235, 0.3);
    border-radius: 10px;
}

.loading-spinner {
    width: 150px;
    height: 150px;
}