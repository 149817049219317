.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrap_home{
  text-align: center;
  margin: 0 auto;
  /* max-width: 1920px; */
  /* max-width: 1200px; */
  min-width: 1600px;
  max-width: 1900px;
  min-height: 100vh;
  overflow: hidden;
  background-color: var(--view-01, #FBF7FC);;
}

.wrap_header{
  max-width: 100%;
  height: 56px;
  background-color: #63226A;
}

.wrap_board{
  display: flex;
  /* width: 1200px; */
  height: 80vh;
  /* background-color: white; */
  max-height: 660px;
  min-height: 500px;
  margin: 24px auto;
  justify-content: center;
}

.page_number{
  display: flex; 
  justify-content: center;
  /* margin-left: 45%; */

}

.tap_header{
  /* display: flex; */
  float: left;
  width: 240px;
  padding: 15px 0px;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}

.table_frame{
  /* border-radius: 15px; */
  border : 1px solid var(--gray-01, #CCC7CC);
  background: #FFF;
}

.thead_basic{
  background: var(--view-02, #F2EAF3);
  height: 60px;
  /* border-radius: 15px; */
  /* border : 1px solid var(--gray-01, #CCC7CC); */
}

.each_tr_frame{
  /* height: 60px; */
  /* border: 1px solid black; */
  /* border-radius: 15px; */
  /* margin: 1px; */
}

/* .each_content{
} */

tbody tr {
  /* max-height: 49px;
  min-height: 49px; */
  cursor: pointer;
}

.col_width_basic{
  width: 10%;
  border-bottom: 1px solid var(--gray-01, #CCC7CC);
  color: var(--gray-02, #87898C);
}
.col_width_content{
  width: 50%;
  border-bottom: 1px solid var(--gray-01, #CCC7CC);
  color: var(--gray-02, #87898C);
}


.detail_content{
  float: right;
  width: 37%;
  /* height: 80vh; */
  overflow: auto;
  margin-left: 10px;
  border: 1px solid var(--gray-01, #CCC7CC);
  background: #FFF;
}

::-webkit-scrollbar {
  display: none;
}


.detail_content_tap{
  display: flex;
  /* float: left; */
  height: 60px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 24px;

  border-top: 1px solid var(--gray-01, #CCC7CC);
  border-bottom: 1px solid var(--gray-01, #CCC7CC);
  background: var(--view-02, #F2EAF3);

}

.detail_buttonlist{
  display: flex;
}

#page_number {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}


.greeting{
  /* display: flex; */
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid var(--gray-01, #CCC7CC);
  background: var(--white, #FFF);
  margin: 10px;
}

.user-info{
  /* display: flex; */
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid var(--gray-01, #CCC7CC);
  background: aliceblue;
  margin: 10px;

  text-align: left;
}

.each_question{
  /* display: flex; */
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid var(--gray-01, #CCC7CC);
  background: var(--view-01, #FBF7FC);
  margin: 10px;
}

.each_answer{
  /* display: flex; */
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid var(--gray-01, #CCC7CC);
  background: var(--white, #FFF);
  margin: 10px;
}


.button_01{
  /* display: flex; */
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  border-radius: 4px;
  border: 1px solid var(--gray-01, #CCC7CC);
}

.button_02{
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 4px;
  border: 1px solid var(--gray-01, #CCC7CC);
}





.edit_input_frame{
  width: 90%;
  height: 90px;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

/* .each_tr_frame {
  border: 1px solid black;
} */

.each_tr_frame tr:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.each_tr_frame tr:last-child {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}


/* 인트로(로그인)페이지 */
.intro-frame{
  width: 500px;
  height: 550px;
  border: 3px solid #63226A;
  background-color: #FBF7FC;
  margin: 10px;
  padding: 20px;
  overflow: auto;
  position: relative;
  top: 10%;
  left: 50%;
  transform: translateX( -50% );
}

.search-button{
  background-color: #63226A;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  font-size: larger;
  font-weight: 600;
}

.intro-imgcontainer{
  text-align: center;
  margin: 40px 0 40px 0;
  position: relative;  
}

/* Full-width input fields */
input[type=text], input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.input-data{
  float: left;
  margin-left: 15px;
}

.selected_page {
  background-color: #63226a;
  color: #fff;
}

#page_number .each_page_num:not(.selected_page) {
  background-color: #ead3ecb8;
}

.each_page_num {
  cursor: pointer;
  padding: 8px;
  margin: 0 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* 이전 및 다음 버튼의 스타일 (옵션) */
.page-prev, .page-next {
  cursor: pointer;
  padding: 8px;
  margin: 0 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
}

/* 취소 모달 팝업 */
*:before, *:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.dialog {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog__inner {
  width: 500px;
  height: 200px;
  padding: 25px;
  border-radius: 5px;
  background-color: #FBF7FC;
}

.dialog__inner h1 {
  font-size: 25px;
  margin: 0;
  color: red;
  text-align: center;
}

.dialog__inner p {
  text-align: center;
  height: 70px;
  line-height: 40px;
  padding-top: 7px;
  font-size: 18px;
}

.dialog__btn__wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 30px;
}

.dialog__btn__wrap button {
  width: 100px;
  height: 25px;
}

#confirmDelete {
  background-color: #c30c0c;
  color: #FFF;
  border: none;
  border-radius: 5px;
  height: 30px;
  font-size: 15px;
}

#cancelDelete {
  background-color: #cecdcd;
  border-radius: 5px;
  height: 30px;
  font-size: 15px;
}

.like_button{
  width: 40px;
  margin: 5px;
}

.pdf-upload-section{
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.pdf-upload-label {
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  background-color: #ffb4da82;
  display: block;
}

.pdf-upload-label:hover {
  background-color: #2e200e8f;
  color: white;
}

.pdf-upload-label img {
  width: 60px;
  height: 60px;
}

.pdf-upload-label input {
  display: none;
}

.upload-buttons {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
}

.btn-server {
  margin-left: 10px;
  display: inline-block;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}